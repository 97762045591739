<template>
        <v-expansion-panel v-model="panel">
            <v-expansion-panel-header>
                Espacio de Testing (Facturación Electrónica)
                <template v-slot:actions>
                    <v-icon color="primary" @click.native.stop="reloadp++">
                        mdi-refresh
                    </v-icon>
                    <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})">
                        mdi-close
                    </v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-card>
                    <v-tabs
                            v-model="tab"
                            dark background-color="primary"
                            icons-and-text
                    >
                        <v-tabs-slider  color="red"></v-tabs-slider>

                        <v-tab href="#tab-1">
                            Formulario de testing
                            <v-icon>mdi-store</v-icon>
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item value="tab-1">
                            <v-card flat>
                                <v-card-text>
                                    <div class="white" style="padding: 25px; border-radius: 5px; border: 1px solid; border-color: black; ">
                                        <v-row>
                                            <v-col cols="12">
                                                <h3>Authenticación a la Api:</h3>
                                            </v-col>
                                            <v-col cols="5">
                                                <v-text-field outlined dense v-model="auth.user" label="Usuario">
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="5">
                                                <v-text-field outlined dense type="password" v-model="auth.pwd" label="Contraseña">
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="2">
                                                <v-btn @click="authApiMh()">Probar</v-btn>
                                            </v-col>
                                        </v-row>

                                        <v-divider></v-divider>
                                        <br/>

                                        <v-row>
                                            <v-col cols="12">
                                                <h3>Firmador de DTE:</h3>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field outlined dense v-model="firmador.nit" label="NIT">
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="2">
                                                <v-select outlined dense  attach v-model="firmador.activo" :items="['true', 'false']" label="Activo">
                                                </v-select>
                                            </v-col>

                                            <v-col cols="4">
                                                <v-text-field outlined dense v-model="firmador.passwordPri" type="password" label="Contraseña privada">
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="2">
                                                <v-btn @click="firmadorMh()">Probar</v-btn>
                                            </v-col>

                                            <v-col cols="12">
                                                <textarea
                                                    rows="4"
                                                    v-model="dteJSON"
                                                    lang="json" 
                                                    class="textarea-code"
                                                    style="white-space: pre-wrap; width: 100% !important">
                                                </textarea>
                                            </v-col>
                                        </v-row>

                                        <br/>
                                        <v-divider></v-divider>
                                        <br/>

                                        <v-row v-if="token != null && token != ''">
                                            <v-col cols="12">
                                                <h3>Recepción DTE:</h3>
                                            </v-col>

                                            <v-col cols="2">
                                                <v-select outlined dense  attach :items="['00']" v-model="recepciondte.ambiente" label="Ambiente">
                                                </v-select>
                                            </v-col>

                                            <v-col cols="3">
                                                <v-text-field outlined dense v-model="recepciondte.idEnvio" label="ID Envío">
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="2">
                                                <v-text-field outlined dense v-model="recepciondte.version" label="Versión de JSON del DTE">
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="3">
                                                <v-select outlined dense  attach v-model="recepciondte.tipoDte" :items="typeDte" item-text="value" item-value="key" label="Tipo de DTE">
                                                </v-select>
                                            </v-col>

                                            <v-col cols="2">
                                                <v-btn @click="firmadorMh()">Probar</v-btn>
                                            </v-col>

                                            <v-col cols="12">
                                                <v-text-field outlined dense v-model="recepciondte.codigoGeneracion" :value="recepciondte.codigoGeneracion" label="Código de generación" disabled="true">
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="12">
                                                <label>Documento tributario electrónico firmado a transmitir: </label>
                                                <textarea
                                                    rows="4"
                                                    v-model="documento"
                                                    lang="json" 
                                                    class="textarea-code"
                                                    style="white-space: pre-wrap; width: 100% !important">
                                                </textarea>
                                            </v-col>
                                        </v-row>



                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
</template>

<script>
    import {v4 as uuidv4 } from 'uuid'

    export default {
        name: "ApiMh",
        props: ['win'],
        data () {
            return {
                tab: null,
                panel: 0,
                reloadp: 0,

                auth: {},
                token: '',

                firmador: {},
                dteJSON: '',

                recepciondte: {},
                typeDte: [
                    {key: '01', value: 'Factura'},
                    {key: '03', value: 'Comprobante de crédito fiscal'},
                    {key: '04', value: 'Nota de remisión'},
                    {key: '05', value: 'Nota de crédito'},
                    {key: '06', value: 'Nota de débito'},
                    {key: '07', value: 'Comprobante de retención'},
                    {key: '08', value: 'Comprobante de liquidación'},
                    {key: '09', value: 'Documento contable de liquidación'},
                    {key: '11', value: 'Facturas de exportación'},
                    {key: '14', value: 'Factura de sujeto excluido'},
                    {key: '15', value: 'Comprobante de donación'},
                ],
                documento: ''
            }
        },
        created() {
            
        },
        mounted() {
            this.recepciondte.codigoGeneracion = uuidv4()
        },
        methods: {
            authApiMh(){
                const data = new FormData()
                data.append('user', this.auth.user)
                data.append('pwd', this.auth.pwd)
                this.$APIMH.apitest.auth(data).then(res => {
                    if(res.status == 'OK'){
                        alert(JSON.stringify(res))
                        this.token = res.body.token
                    }
                })
            },

            firmadorMh() {
                const parsedJson = JSON.parse(this.dteJSON)
                this.firmador.dteJson = Object.assign({}, parsedJson)
                console.log(this.firmador)
                
                this.$APIMH.firmador.firmarDTE(this.firmador).then(res => {
                    if(res.status == 'OK'){
                        this.documento = res.status.body
                        this.recepciondte.documento = this.documento
                    }
                    alert(JSON.stringify(res))
                })
                .catch(err => {
                    alert(JSON.stringify(err))
                })
            },

            recepcionDteMh() {
                const parsedJson = JSON.parse(this.documento)
                this.recepciondte.documento = Object.assign({}, parsedJson)
                console.log(this.recepciondte)
                
                this.$APIMH.apitest.recepcionDTE(this.recepciondte).then(res => {
                    alert(JSON.stringify(res))
                })
                .catch(err => {
                    alert(JSON.stringify(err))
                })
            }
        }
    }
</script>

<style scoped>
    .sidebar-options{
        display: none;
    }

    .textarea-code {
        font-family: 'Courier New', Courier, monospace;
        font-size: 14px;
        line-height: 1.5;
        padding: 10px;
        border: 1px solid #ccc;
        background-color: #f5f5f5;
        color: #333;
    }

</style>
